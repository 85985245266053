

















































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'

@Component({
  name: 'AgentSetting',
})
export default class AgentSetting extends VueBase {
  private form = {
    vul_verifiy: '0',
  }

  async getInfo() {
    const keys = []
    for (let key in this.form) {
      keys.push(key)
    }
    const res = await this.services.setting.profileGet({
      keys,
    })
    if (res.status === 201) {
      res.data.forEach((item: any) => {
        this.form[item.key] = item.value
      })
    } else {
      this.$message.error(res.msg)
    }
  }

  async profileModified(val: any) {
    if(val == 1) {
      const { status, msg, data } =
      await this.services.setting.vulRecheckPayload({
        page: 1,
        page_size: 20,
        language_id: 1,
      })
      
      if (status !== 201) {
        this.$message.error(msg)
        return
      }
      if (data?.length < 1) {
        this.$confirm('暂无可用规则，请点击跳转至规则配置', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          this.$router.push('/setting/hookRule')
        })
        return
      }
    }
    const data = []
    for (let key in this.form) {
      data.push({
        value: String(this.form[key]),
        key,
      })
    }
    const res = await this.services.setting.profileModified(data)
    if (res.status === 201) {
      this.$message.success(res.msg)
    } else {
      this.$message.error(res.msg)
    }
    this.getInfo()
  }

  created() {
    this.getInfo()
  }

  toSetting() {
    this.$router.push({ name: 'sysInfo' })
  }
}
