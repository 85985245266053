







import VueBase from '@/VueBase'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'Status',
})
export default class Status extends VueBase {
  @Prop() state!: any

  get state_text() {
    switch (this.state) {
      case 2:
        return { class: '', label: '运行中' }
      case 3:
        return { class: 'close', label: '暂停中' }
      case 4:
        return { class: 'uninstrall', label: '已离线' }
      default:
        return { class: 'uninstrall', label: '已离线' }
    }
  }
}
