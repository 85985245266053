












import VueBase from '@/VueBase'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'Language',
})
export default class Language extends VueBase {
  @Prop() language?: any
}
