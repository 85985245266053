

















import VueBase from '@/VueBase'
import { Component, Prop } from 'vue-property-decorator'
@Component({
  name: 'Resource',
})
export default class Resource extends VueBase {
  @Prop() cpu_rate?: any
  @Prop() disk_rate?: any
  @Prop() memory_rate?: any
}
