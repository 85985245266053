































































































































































































































































































































































































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { AgentListObj } from '@/views/setting/types'
import { formatTimestamp } from '@/utils/utils'
import AgentSetting from './components/agentSetting.vue'
import Status from './components/status.vue'
import Language from './components/language.vue'
import Resource from './components/resource.vue'

@Component({
  name: 'AgentManage',
  filters: {
    formatTimestamp(date: number | any) {
      if (!date) {
        return ''
      }
      return formatTimestamp(date)
    },
  },
  components: {
    AgentSetting,
    Status,
    Language,
    Resource,
  },
})
export default class AgentManage extends VueBase {
  private activeProject: any = {}
  private drawer = false

  private last_days = 2

  async openDrawer(row: AgentListObj) {
    const res = await this.services.setting.stat({
      id: row.id,
    })
    this.activeProject = row
    this.activeProject.api_count = res.data.api_count
    this.activeProject.sca_count = res.data.sca_count
    this.activeProject.vul_count = res.data.vul_count
    this.drawer = true
  }

  private toDetail(id: string) {
    this.$router.push(`/project/projectDetail/${id}`)
  }
  private summary = {}
  private tableData: Array<AgentListObj> = []
  private total = 0
  private page = 1
  private state = 1
  private pageSize = 10
  private currentPageSize = 0
  private currentPageDelete = 0
  private deleteDialogOpen = false
  private deleteSelectId = 0
  private stateOptions = [
    { value: 1, label: this.$t('views.agentManage.running') },
    { value: 0, label: this.$t('views.agentManage.not_running') },
  ]
  private searchValue = ''
  private multipleSelection: any = []
  private async update(id: any, type: any) {
    const res = await this.services.setting.update_core({
      id: id,
      core_status: type,
    })
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.getTableData(true)
      return
    }
    this.$message.error(res.msg)
  }

  private async updateAll(type: any) {
    const res = await this.services.setting.update_core_all({
      core_status: type,
    })
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.getTableData(true)
      return
    }
    this.$message.error(res.msg)
  }
  private async examine(row: any) {
    const res = await this.services.setting.audit({ id: row.id })
    if (res.status === 201) {
      this.$message.success(res.msg)
      this.getTableData(true)
    } else {
      this.$message.error(res.msg)
    }
  }
  created() {
    this.getTableData(true)
    this.timer = setInterval(() => {
      // this.reflashTable()
      this.getTableData()
    }, 5000)
  }
  private timer: any
  private timeOuter: any
  private searchTable() {
    if (this.timeOuter) {
      clearTimeout(this.timeOuter)
      this.timeOuter = null
    }
    this.timeOuter = setTimeout(() => {
      this.getTableData(true)
    }, 400)
  }

  private exportAgent(id: any) {
    window.open('/api/v1/agent/log/' + id)
  }

  private openEdit(item: any) {
    item.isEdit = true
    item.backAlias = item.alias
  }

  private closeEdit(item: any) {
    item.isEdit = false
    item.alias = item.backAlias
  }

  get userInfo() {
    return this.$store.getters.userInfo
  }

  private async enterEdit(item: any) {
    const res = await this.services.setting.aliasModified({
      id: item.id,
      alias: item.alias,
    })
    if (res.status === 201) {
      this.$message.success(res.msg)
      item.isEdit = false
    } else {
      this.$message.error(res.msg)
    }
  }

  private handleSelectionChange(val: any) {
    this.multipleSelection = val
  }
  private beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
  private changeState(item: any) {
    this.state = item
    this.page = 1
    this.getTableData(true)
    clearInterval(this.timer)
    this.timer = null
    this.timer = setInterval(() => {
      // this.reflashTable()
      this.getTableData()
    }, 5000)
  }
  private currentChange(val: number | string) {
    this.page = parseInt(`${val}`)
    this.getTableData(true)
  }

  private sizeChange(val: number | string) {
    this.pageSize = parseInt(`${val}`)
    this.getTableData(true)
  }

  // private async reflashTable() {
  //   if (this.tableData.some((item: any) => item.isEdit)) {
  //     return
  //   }
  //   const params = {
  //     page: this.page,
  //     page_size: this.pageSize,
  //     state: this.state,
  //   }
  //   const { status, msg, data } = await this.services.setting.agentList(params)
  //   if (status !== 201) {
  //     this.$message({
  //       type: 'error',
  //       message: msg,
  //       showClose: true,
  //     })
  //     return
  //   }
  //   const dataMap = {}
  //   data.agents &&
  //     data.agents.forEach((item: any) => {
  //       return (dataMap[item.id] = item)
  //     })
  //   this.tableData.forEach((item: any) => {
  //     for (const key in item) {
  //       item[key] = dataMap[item.id][key]
  //     }
  //     try {
  //       item.system_load = JSON.parse(item.system_load)
  //     } catch (err) {
  //       item.system_load = { rate: 0 }
  //     }
  //   })
  //   this.getSummy()
  // }

  private agentSelectable(row: any) {
    return row.state !== 4
  }

  private async getTableData(showLoading = false) {
    const multipleSelectionID = this.multipleSelection.map(
      (item: any) => item.id
    )
    const params = {
      page: this.page,
      page_size: this.pageSize,
      state: this.state,
      last_days: this.last_days,
      project_name: this.searchValue,
    }
    if (showLoading) {
      this.loadingStart()
    }
    const { status, msg, data, page } = await this.services.setting.agentList(
      params
    )
    if (showLoading) {
      this.loadingDone()
    }
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    if (data.agents && data.agents.length === 0 && this.page > 1) {
      this.page--
      await this.getTableData(true)
      return
    }
    this.tableData = data.agents
    // this.tableData.forEach((item: any) => {
    //   this.$set(item, 'isEdit', false)
    //   this.$set(item, 'backAlias', '')
    //   try {
    //     item.system_load = JSON.parse(item.system_load)
    //   } catch (err) {
    //     item.system_load = { rate: 0 }
    //   }
    // })
    this.currentPageSize = data.length
    this.total = data.summary.alltotal
    this.currentPageDelete = 0
    this.getSummy()
    this.tableData.filter((item: any) => {
      return multipleSelectionID.some((m: any) => {
        if (item.id === m) {
          this.$nextTick(() => {
            const tab: any = this.$refs.agentTable
            tab.toggleRowSelection(item, true)
          })
        }
      })
    })
  }

  private async getSummy() {
    const res = await this.services.setting.summary()
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.summary = res.data
  }

  private async agentInstall(id: string | number) {
    this.loadingStart()
    const { status, msg } = await this.services.setting.agentInstall({
      id: parseInt(`${id}`),
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTableData()
  }

  private async deleteAgents() {
    if (this.multipleSelection.length === 0) {
      this.$message.warning(
        this.$t('views.agentManage.selectWarning') as string
      )
      return
    }
    this.$confirm(
      this.$t('views.agentManage.delAgentInfo') as string,
      this.$t('views.agentManage.delAgentPop') as string,
      {
        confirmButtonText: this.$t(
          'views.agentManage.confirmButtonText'
        ) as string,
        cancelButtonText: this.$t(
          'views.agentManage.cancelButtonText'
        ) as string,
        type: 'warning',
      }
    ).then(async () => {
      this.loadingStart()
      const params = {
        ids: String(
          this.multipleSelection.map((item: any) => parseInt(item.id))
        ),
      }
      const { status, msg } = await this.services.setting.deleteAgents(params)
      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      this.$message({
        type: 'success',
        message: msg,
        showClose: true,
      })
      await this.getTableData()
    })
  }

  private async agentStart(id: any) {
    if (this.selectAll) {
      this.updateAll(3)
      return
    }
    if (!this.state) {
      return
    }
    if (id === 0) {
      if (this.multipleSelection.length === 0) {
        this.$message.warning(
          this.$t('views.agentManage.selectWarning') as string
        )
        return
      }
    }
    this.loadingStart()
    let params = {}
    if (id) {
      params = { id: parseInt(id) }
    } else {
      params = {
        ids: String(
          this.multipleSelection.map((item: any) => parseInt(item.id))
        ),
      }
    }
    const { status, msg } = await this.services.setting.agentStart(params)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$message({
      type: 'success',
      message: msg,
      showClose: true,
    })
    await this.getTableData()
  }

  private async logExport() {
    if (this.selectAll) {
      return
    }
    if (!this.state) {
      return
    }
    if (this.multipleSelection.length === 0) {
      this.$message.warning(
        this.$t('views.agentManage.selectWarning') as string
      )
      return
    }
    const params = {
      ids: this.multipleSelection.map((item: any) => parseInt(item.id)),
    }
    const { status, msg } = await this.services.setting.log_export_batch({
      ids: params.ids,
      mode: 1,
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$message({
      type: 'success',
      message: '导出日志中，请留意通知信息',
      showClose: true,
    })
  }

  private async agentUpdate(id: any) {
    if (this.selectAll) {
      this.updateAll(8)
      return
    }
    if (!this.state) {
      return
    }
    if (id === 0) {
      if (this.multipleSelection.length === 0) {
        this.$message.warning(
          this.$t('views.agentManage.selectWarning') as string
        )
        return
      }
    }
    this.loadingStart()
    let params: any = {}
    if (id) {
      params = { id: parseInt(id) }
    } else {
      params = {
        ids: String(
          this.multipleSelection.map((item: any) => parseInt(item.id))
        ),
      }
    }
    const { status, msg } = await this.services.setting.update_core({
      id: params.id,
      agent_ids: params.ids,
      core_status: 8,
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$message({
      type: 'success',
      message: msg,
      showClose: true,
    })
    await this.getTableData()
  }

  private selectAll = false

  private async agentStop(id: any) {
    if (this.selectAll) {
      this.updateAll(4)
      return
    }
    if (!this.state) {
      return
    }
    if (id === 0) {
      if (this.multipleSelection.length === 0) {
        this.$message.warning(
          this.$t('views.agentManage.selectWarning') as string
        )
        return
      }
    }
    this.loadingStart()
    let params: any
    if (id) {
      params = { id: parseInt(id) }
    } else {
      params = {
        ids: String(
          this.multipleSelection.map((item: any) => parseInt(item.id))
        ),
      }
    }
    const { status, msg } = await this.services.setting.agentStop(params)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.$message({
      type: 'success',
      message: msg,
      showClose: true,
    })
    await this.getTableData()
  }

  private async agentUninstall(id: string | number) {
    this.loadingStart()
    const { status, msg } = await this.services.setting.agentUninstall({
      id: parseInt(`${id}`),
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    await this.getTableData()
  }

  private async doDelete(id: string | number) {
    this.$confirm(
      this.$t('views.strategyManage.deleteWarning') as string,
      this.$t('views.strategyManage.deletePop') as string,
      {
        confirmButtonText: this.$t('views.strategyManage.enter') as string,
        cancelButtonText: this.$t('views.strategyManage.clear') as string,
        type: 'warning',
      }
    ).then(async () => {
      const { status, msg } = await this.services.setting.agentDelete({
        id: Number(id),
      })
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      this.$message({ type: 'success', message: msg, showClose: true })

      this.currentPageDelete = this.currentPageDelete + 1
      if (this.currentPageDelete === this.currentPageSize) {
        this.page = this.page - 1
      }
      await this.getTableData()
      this.deleteSelectId = 0
    })
  }

  private async agentDelete() {
    this.loadingStart()
    const { status, msg } = await this.services.setting.agentDelete({
      id: this.deleteSelectId,
    })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
  }

  async changeCoreControl(id: any, e: any) {
    let type = 3
    switch (e) {
      case 1:
        type = 3
        break
      case 0:
        type = 4
        break
    }
    await this.update(id, type)
    // this.reflashTable()
    this.getTableData()
  }
}
